import React, { useState } from 'react';
import { TextInput, Radio, PhoneInput, Modal } from 'components';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import Normalizer from 'helpers/normalizer';
import Validator from 'helpers/validator';
import { useSelector } from 'react-redux';
import ApiHelper from 'helpers/apiHelper';
import * as url from 'helpers/urlHelper';
import Routes from 'routes/routes';

const selector = formValueSelector('REGISTER_CONTACT_FORM');

const FAIL_REASONS = {
  PHONE: 'phone',
  EMAIL: 'email',
  UNKNOWN: 'unknown',
};

const REGISTER_MESSAGES = {
  PHONE:
    'The phone number entered has already been registered. Please use another phone number.',
  EMAIL:
    'The email entered has already been registered. Please use another email.',
  UNKNOWN: 'Something went wrong. Please try again.',
  SUCCESSFUL: 'Register successful!',
};

const DashboardRegisterForm = ({ handleSubmit, reset }) => {
  const checkConfirm = useSelector((state) => selector(state, 'checkConfirm'));
  const [registerStatus, setRegisterStatus] = useState({
    status: null,
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const initRegister = async (values) => {
    setLoading(true);
    const payload = {
      email: values.email,
      business_name: values.businessName,
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone.replaceAll('-', ''),
      job_title: values.jobTitle,
      street: values.businessAddress,
      app_name: 'MAS_PAY',
    };
    try {
      await ApiHelper.post(url.POST_REGISTER_BY_PHONE, payload);
      reset();
      setIsShowModal(true);
      setRegisterStatus({
        status: 'success',
        message: REGISTER_MESSAGES.SUCCESSFUL,
      });
    } catch (error) {
      if (error.response) {
        handleError(error.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values) => {
    initRegister(values);
  };

  const handleCloseModal = () => {
    setIsShowModal(false);
    if (
      registerStatus.failReason === FAIL_REASONS.PHONE ||
      registerStatus.failReason === FAIL_REASONS.EMAIL
    ) {
      const element = document.getElementsByName(registerStatus.failReason)[0];
      if (element) {
        element.focus();
      }
    }
    setRegisterStatus({
      status: '',
      failReason: '',
      message: '',
    });
  };

  const handleError = (error) => {
    setIsShowModal(true);
    if (error.phone) {
      setRegisterStatus({
        status: 'fail',
        failReason: FAIL_REASONS.PHONE,
        message: REGISTER_MESSAGES.PHONE,
      });
    } else if (error.email) {
      setRegisterStatus({
        status: 'fail',
        failReason: FAIL_REASONS.EMAIL,
        message: REGISTER_MESSAGES.EMAIL,
      });
    } else {
      setRegisterStatus({
        status: 'fail',
        failReason: FAIL_REASONS.UNKNOWN,
        message: REGISTER_MESSAGES.UNKNOWN,
      });
    }
  };

  return (
    <div className="container">
      <div className="content">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <form action="contact.php" className="form" method="post">
              <p className="text-center text-danger fs-12px mb-30">
                The field is required mark as *
              </p>
              <div className="row">
                <div className="col-lg-6">
                  <Field
                    name="firstName"
                    component={TextInput}
                    className="form-group mb-20"
                    placeholder="First Name*:"
                    normalize={Normalizer.capitalize}
                    validate={[Validator.isRequired]}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="lastName"
                    component={TextInput}
                    className="form-group mb-20"
                    placeholder="Last Name*:"
                    normalize={Normalizer.capitalize}
                    validate={[Validator.isRequired]}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="phone"
                    component={PhoneInput}
                    className="form-group mb-20"
                    placeholder="Phone Number*:"
                    normalize={Normalizer.normalizePhone}
                    validate={[Validator.isRequired]}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="email"
                    component={TextInput}
                    className="form-group mb-20"
                    placeholder="Email Address*:"
                    validate={[Validator.isRequired, Validator.isEmail]}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="businessName"
                    component={TextInput}
                    className="form-group mb-20"
                    placeholder="Business Name:"
                    normalize={Normalizer.capitalize}
                  />
                </div>
                <div className="col-lg-6">
                  <Field
                    name="businessAddress"
                    component={TextInput}
                    className="form-group mb-20"
                    placeholder="Business Address:"
                    normalize={Normalizer.capitalize}
                  />
                </div>
                <div className="col-lg-12">
                  <Field
                    name="jobTitle"
                    component={TextInput}
                    className="form-group mb-20"
                    placeholder="Job Title:"
                  />
                </div>
                <div className="col-lg-12">
                  <div className="form-group mb-20">
                    <span className="content-checkbox">
                      *I agree <a href="https://maspay.us/">MasPay.us</a> can
                      email me about its information, newsletter, services,
                      products and offerings.
                    </span>
                    <div className="form-check form-check-inline">
                      <Field
                        name="checkConfirm"
                        component={Radio}
                        type="radio"
                        className="form-check-input"
                        value="yes"
                        label="Yes"
                        validate={[Validator.isRequired]}
                      />
                    </div>
                    <div className="form-check form-check-inline">
                      <Field
                        name="checkConfirm"
                        component={Radio}
                        type="radio"
                        className="form-check-input"
                        value="no"
                        label="No"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <span className="content-checkbox">
                      By submitting your email address, you acknowledge that you
                      have read the{' '}
                      <a href={Routes.TERM}>Terms and Conditions</a> and that
                      you consent to our processing data in accordance with the{' '}
                      <a href={Routes.POLICY}>Privacy Statement</a>. You can
                      opt-out anytime by sending us an email message to{' '}
                      <a href="mailto:maspayinfo@maspay.us">
                        maspayinfo@maspay.us
                      </a>
                      .
                    </span>
                  </div>
                </div>
                <div className="col-lg-12 text-center">
                  <button
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    className="btn btn-warning btn-lg button-size"
                    disabled={checkConfirm !== 'yes' || loading}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {isShowModal && registerStatus.message && (
        <Modal
          content={registerStatus.message}
          handleClose={handleCloseModal}
        />
      )}
    </div>
  );
};

DashboardRegisterForm.propTypes = {
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
};

export default reduxForm({
  form: 'REGISTER_CONTACT_FORM',
})(DashboardRegisterForm);
