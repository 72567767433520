import React from 'react';
import PropTypes from 'prop-types';
import { Footer, Header } from 'components';
import './styles.scss';

const Policy = ({ history }) => {
  return (
    <>
      <Header history={history} />
      <div className="omny-registerTerm">
        <section id="policy">
          <div className="container">
            <h3>Lunex Telecom Privacy Policy</h3>
            <p>
              Lunex Telecom is dedicated to protecting the privacy of
              individuals whose personal data we hold for information processing
              or collect for our business use. This includes Licensees and users
              of its Platform-as-a Service software and virtual environment. It
              also includes visitors to our general website, prospects who
              request information about us, in addition to our Licensees. This
              Privacy Policy describes how Lunex Telecom may collect (or not
              collect), use, and disclose personal data, and options regarding
              Lunex Telecom’s holding and use of various forms of personal data
              in the course of its services and business.
            </p>
            <p>
              Lunex Telecom’s Services are not intended for use by minors.
              Minors are children under the age of eighteen (18). Lunex Telecom
              will not knowingly collect personal data from persons under the
              natural age of eighteen. If you are under the age of eighteen
              (18), do not use our Services and do not provide your information
              to Lunex Telecom.
            </p>
            <h2>Data We Collect from general website</h2>
            <p>
              <strong>Cookies and Other Technologies.</strong> The Lunex Telecom
              website, and navigation through it, may include our use cookies,
              web beacons, pixels, or other technologies to collect data about
              your access, your device and interaction or activity on the
              website. This is used to improve the customer’s experience, gather
              business analytics on our services, better navigation, provide
              future functionality, recognize your access, and personalize the
              customer experience from any device. This may include navigation
              form the landing point on our general website though to your
              Licensee account setup and use of the Platform thereafter.
            </p>
            <p>
              <strong>SEO and Web Marketing Service Providers.</strong> Lunex
              Telecom, on its own, or through third party providers, may collect
              information from the general website to better understand, reach
              and direct potential customer to our website and our Services.
              This may include data collected to improve our Search Engine
              Optimization (SEO). Lunex Telecom may collect personal data about
              you from these purposes and in relation to such activities. Lunex
              Telecom may use such data (collected via cookies and other
              technologies) to plan and manage our advertising on this and other
              sites or to provide you offers or advertisements based upon your
              browsing or landing page interest and inquiries. If “follow” or
              “like” Lunex Telecom on third party social media sites, Lunex
              Telecom will see your public profile and may direct advertising to
              you through social media.
            </p>
            <h2>Data We Collect to render our software Services</h2>
            <p>
              Lunex Telecom collects personal data directly from Licensed Users
              and prospect inquires to our Services. We also collect data from
              interactions on our website. Lunex Telecom may collect personal
              data from additional sources, and we may combine this data with
              data we already have about you to transact business with you. This
              personal data may include the following:
            </p>
            <p>
              <strong>User Contact details.</strong> Lunex Telecom may collect
              your contact details of Licensed Users, such as your name,
              company, address, primary contact telephone number and email
              address. In the software Services provided you, Lunex Telecom may
              collect username (if more than one authorized user), email
              address, and associated IP address as part of our Services to keep
              proper records for use and billing.
            </p>
            <p>
              <strong>Account creation and use.</strong> When you create a Lunex
              Telecom account, we collect your user’s contact details and a
              password for service and support, including verifications and
              password re-sets. Lunex Telecom may further collect information
              about your logins and how you interact with the Platform,
              functions commonly used, and our software Services performance to
              improve our Service and the Licensee user’s experience. This
              includes features you use, click, upload, organize, as well as
              data migrations and log information about the Services you use or
              regularly use from your account.
            </p>
            <p>
              <strong>Billing data.</strong> When you access a Licensed User
              account for use of our functionality and Platform’s software
              features, Lunex Telecom may collect billing and payment
              information. For automated and credit card payments, we use a
              third-party payment processor to process the payment. We do not
              collect your credit card number, expiration date, or pin number
              for any other purpose. The transaction details we retain are those
              details that allow us to verify that you paid for Licenses, usage
              credits/tokens and the Services or transaction, including price,
              currency, date of payment, payment method, name, and account name.
              That information is combined information with your Licensed
              account information.
            </p>
            <p>
              <strong>Support data.</strong> Data, content, and feedback given
              to Lunex Telecom through the customer support or any chat
              functionality available on our website, within our Services, or
              other sites that we control may be collected for support and
              operational purposes. This may include information about your
              technology, internet connection, screenshots or any other data
              used for troubleshooting problems or improving our platform,
              software, or functionality.
            </p>
            <h2>Data We Do Not Collect</h2>
            <p>
              Lunex Telecom does not collect certain personal data directly
              from, or on behalf of, Licensed Users. This personal data may
              include the following:
            </p>
            <p>
              <strong>Licensee’s client contact data.</strong> The information
              that comprises your client’s contact data (e.g., name, telephone
              numbers, emails, consents, and other personalized information used
              to manage your contact data) is derived strictly from your
              interaction and contact with your client. This includes physical
              documentation you maintain in your business location or
              information electronically stored by you and entered our system
              after the client’s expressed written consent to be contacted. This
              is information that is always strictly under your control. Lunex
              Telecom shall treat this as aggregated data for organizational and
              contact information processing in on-demand contact messaging you
              send. Upon termination or license expiration of Lunex Telecom’s
              dealings with the Licensee, the Licensee will be given a
              reasonable opportunity to download any client content data in a
              portable form and any data on our Platform will thereafter be
              destroyed. This includes the groups, subgroups, or special groups
              you customize on the Platform. Lunex Telecom likewise does not
              independently acquire, develop, generate, or otherwise provide
              Licensees any client contact data. Lunex Telecom does not resell
              your client contact data. Your data was developed by you within
              the context of your business and is yours exclusively. Lunex
              Telecom has no part in the collection and acquisition of this
              data.
            </p>
            <p>
              <strong>Migrated Contact Data.</strong> Any information uploaded
              by you or migrated on processing demand to Lunex Telecom that
              comprises your client’s contact data is your data, developed by
              you within the context of your business. and is yours exclusively.
              It is treated by Lunex Telecom, both before and after upload or
              migrated, as such.
            </p>
            <p>
              <strong>Verification of Contact.</strong> Anytime Lunex Telecom
              processes and verification of a contact of yours or from your
              account or from lists of your client contact data, it retains no
              information in the processing of that request other than the
              simple result of verification. The information that comprises the
              client contact data before and after verification is your data,
              developed by you within the context of your business and is yours
              exclusively.
            </p>
            <p>
              Lunex Telecom may maintain information comprising certain personal
              data of third parties with whom Licensee hold consent to be
              contact and business dealings within the Licensee’s virtual
              platform account. This information is maintained by Lunex Telecom
              only to hold necessary information to perform the software and
              organizational processing purposes under the Licensee’s limited
              license to use the software and for no other purpose.
            </p>
            <h2>Data under Legal obligations</h2>
            <p>
              <strong>
                California Privacy Rights and Customers’ Contact Data.
              </strong>{' '}
              Under the California Consumer Privacy Act (“CCPA”) (Cal. Civ. Code
              § 1798.100, et seq.) California residents have specific rights
              regarding the use of their personal information. Lunex Telecom
              operates as a Platform-as-a-Service provider that provides
              software and data processor with respect to a Licensed User and
              the Licensed User’s customer contact data. Lunex Telecom only uses
              such data for the purpose of providing its Platform and software
              functionality to its Licensed Users. Lunex Telecom may access
              Customers’ Contact Data to provide our Services, to address
              technical or other service problems, or to comply with
              instructions of our Customers who provided the data. Lunex Telecom
              does not access, retain, use, or disclose Customers’ Contact Data
              for any other purpose.
            </p>
            <p>
              <strong>Lawful Purposes and Lawful Legal Requests.</strong> Where
              required by law, or Lunex Telecom determines, or it is necessary
              to protect our legal rights and reasonable business interests
              (including the protection of intellectual property of Lunex
              Telecom), or the legal rights of others impacted by your use of
              our Services in ways not intended under your Limited License, we
              may also use information about you in connection with legal claims
              (ourselves or any third parties) and/or for purpose of regulatory
              compliance. Personal data may be used for activities where your
              use impacts our reasonable business interests and as permitted or
              required by law.{' '}
              <strong>
                This includes lawful legal requests from the courts, under
                lawful subpoena, or by law enforcement authorities and agencies.
              </strong>{' '}
              If we generate a marketing list based on inquires or information
              you provide us with consent, you may request to be deleted or
              removed from the list at any time either temporarily or
              permanently. Lunex Telecom may disclose personal data to prevent
              or stop an attack on our platform or network or to prevent
              attempts to defraud our users or breach our security protocols.
              Lunex Telecom may disclose your data in connection with any
              merger, financing, acquisition, bankruptcy, dissolution,
              transaction or proceeding involving the sale, transfer,
              divestiture or disclosure of all or a portion of our business or
              assets to another company.
            </p>
            <h2>Prohibited Content on Our Platform.</h2>
            <p>
              Our Platform and Service is meant for businesses to better engage
              the existing customers they know and value. Protection of the
              rights of parties messaged is just as important as the use of our
              Platform by our clients. Blind Telemarketing through text messages
              or business that runs counter to general consumer interests is not
              intended to be used with our Service. The following categories of
              messages are strictly prohibited on our Platform. If Lunex Telecom
              determines, in its sole informed opinion, that such messaging as
              found below is being transmitted by a user of our Platform, it
              will then be necessary for us to protect our legal rights in the
              Platform and reasonable business interests, and either immediately
              block the functionality of messaging from your Account, OR,
              suspend your Account pending a full investigation of any possible
              misuse of our Services. This is a zero-tolerance policy. These
              types of messages include the following content:
            </p>
            <p>
              <strong>
                Marketing of High-Risk Financial Services & Use in Collections
              </strong>
            </p>
            <ul>
              <li>Payday Loans</li>
              <li>Short Term High Interest Loans</li>
              <li>Auto Loans</li>
              <li>Mortgage Loans</li>
              <li>Student Loans</li>
              <li>Debt Collection</li>
              <li>Gambling</li>
              <li>Stock Alerts</li>
              <li>Cryptocurrency</li>
            </ul>
            <p>
              <strong>
                High Risk Business Opportunity Offers and Multi-Level Programs
              </strong>
            </p>
            <ul>
              <li>Deceptive Work from Home Programs</li>
              <li>Risk Investment Opportunities</li>
              <li>Multi-Level Marketing</li>
            </ul>
            <p>
              <strong>3rd Party Telemarketing Solicitations</strong>
            </p>
            <ul>
              <li>Debt Collection or Consolidation</li>
              <li>Debt Reduction</li>
              <li>Credit Repair Programs</li>
              <li>Lead Generation</li>
            </ul>
            <p>
              <strong>
                Controlled Substance Advertisements or Solicitations
              </strong>
            </p>
            <ul>
              <li>Tobacco</li>
              <li>Vape</li>
              <li>Federally Illegal Drugs</li>
            </ul>
            <p>
              <strong>
                Fraudulent, Offensive or Abusive Messages or images links
              </strong>
            </p>
            <ul>
              <li>Abuse of Minors</li>
              <li>Pornography</li>
              <li>Profanity or Hate Speech</li>
              <li>Phishing</li>
              <li>Fraud or Scams</li>
              <li>Deceptive Marketing</li>
            </ul>
            <h2>Our Employees and Privacy.</h2>
            <p>
              At Lunex Telecom, we train all our employees about the importance
              of privacy. Lunex Telecom maintains certain restriction policies
              that give permission to its employees and third-party contractors
              to access personal data only to those who require it to perform
              their jobs. Keeping the privacy of our Licensees and their users
              is a material term of all employment with Lunex Telecom.
            </p>
            <h2>Security Measures.</h2>
            <p>
              Lunex Telecom make access to privacy-sensitive information within
              its Platform subject to rigorous procedural and technological
              controls, consistent with legal requirements and the reasonable
              demands of maintaining a closed-virtual Platform for our Licensees
              and still supporting customer service. Security measures
              implemented by Lunex Telecom are both internal and external to our
              Platform to curb threats of unauthorized access. We may ask for
              multi-tiered verifications from our Licensees and their users from
              time to time to ensure account access is secure. We may require
              updated account verification from inactive Licensees or Users to
              ensure that control of access remains with our Licensed users and
              has not been lost, compromised, or otherwise is vulnerable to
              unauthorized access. Lunex Telecom holds the sole and exclusive
              right to require any user of its Platform to change their password
              for any security reason. Lunex Telecom holds the sole and
              exclusive right to suspend, block or change account access and
              passwords of any user if it, in its sole and reasonable opinion,
              becomes aware or suspects unauthorize access to it Platform from
              an account, or when it reasonably believes that the account or
              device from access is obtained has been lost, compromised, stolen
              or hacked by any outside party. Licensees and Users consent to
              such rights and security measures in the use of our Services.
            </p>
            <h2>Governing law and venue applicable to these policies.</h2>
            <p>
              Lunex Telecom is a company incorporated and operated in the State
              of Georgia and its policies are governed by Georgia law in
              combination with federal law. Any issue, question, controversy, or
              legal claim based upon our privacy policies (be it domestic or
              extra-territorial) shall have exclusive jurisdiction and venue of
              the state and federal courts of Gwinnett County Georgia. By virtue
              of navigation and use of this website, or the Services of Lunex
              Telecom, visitor or users expressly waives all defenses to any
              other jurisdiction and so waives without any consideration of
              conflict of law theories, case law or principles.
            </p>
            <p>
              Lunex Telecom reserves the right to update, supplement or amend
              its privacy policies from time to time as business necessity,
              changes of law or regulation of technologies applicable occur. For
              any questions about our privacy policies, please contact our
              customer service and support departments at:_______________
            </p>
            <p>This policy was last updated on ______________________</p>
          </div>
        </section>
      </div>
      <Footer history={history} />{' '}
    </>
  );
};

Policy.propTypes = {
  history: PropTypes.any,
};

export default Policy;
