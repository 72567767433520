import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BannerCard from 'assets/img/card.png';
import BenefitCard from 'assets/img/benefit-card.png';
import { DashboardRegisterForm } from './component';
import './styles.scss';
import { Footer, Header } from 'components';

const Dashboard = ({ history }) => {
  useEffect(() => {
    const setNoAnimation = document.getElementsByClassName('banner-all')[0];
    if (setNoAnimation) {
      if (history.location.hash === '#contactus') {
        setNoAnimation.classList.add('no-animation');
      } else {
        setNoAnimation.classList.remove('no-animation');
      }
    }
  }, []);
  return (
    <div className="home-page">
      <Header history={history} />
      <section className="hero-5 bg-light elementor-column" id="home">
        <div className="container">
          <div className="row banner-all">
            <div className="selling-popular-gift-container">
              <h1 className="animate__animated animate__fadeInRight">
                <p className="selling-popular-gift ">
                  SELLING POPULAR GIFT CARDS
                </p>
                <p className="selling-popular-gift">AT YOUR STORE TODAY</p>
              </h1>
            </div>
            <div className="sell-gift-cards-container">
              <h2 className="animate__animated animate__fadeInRight">
                <span>
                  Sell Gift Cards From Many Popular Retailers To Your Customers
                  Today With
                </span>
                <b> Maspay.Com</b>
                <span>
                  . We Offer Over 80 Top U.S. Brands Such As AMC Theatres,
                  Chipotle, CVS, Dominos, Nintendo, Sephora, Delta Airlines, And
                  Many More…
                </span>
              </h2>
              <a
                id="getstarted"
                href="#contactus"
                className="nav-link animate__animated animate__fadeInRight"
              >
                {' '}
                <button
                  type="button"
                  className="btn btn-warning btn-lg button-size"
                >
                  Get Started
                </button>
              </a>
            </div>
            <div className="elementor-column banner-image">
              <img
                id="banner-image"
                className="card-1-icon img-center animation-zoom img-fluid animate__animated animate__fadeInLeft"
                src={BannerCard}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="aboutus">
        <div className="container">
          <div className="row">
            <div className="col-md-6 animate__animated animate__fadeInUp">
              <img
                id=""
                className="img-center img-fluid "
                src={BenefitCard}
                alt=""
              />
            </div>
            <div className="col-md-6 content-aboutus animate__animated animate__fadeInUp">
              <h3 className="rt-title-heading">
                Benefits of Selling{' '}
                <strong>
                  MÁS<span className="red">PAY</span>
                </strong>
              </h3>
              <div className="elementor-widget-container">
                1. Generate more sales
              </div>
              <div className="elementor-widget-container">
                2. Attract new customers
              </div>
              <div className="elementor-widget-container">
                3. Convenience for customers to spend more
              </div>
              <div className="elementor-widget-container">
                4. Boost seasonal sales
              </div>
              <div className="elementor-widget-container">
                5. Increase store recognition
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contactus" className="contact section-padding pt-50 style-6">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12 animate__animated animate__fadeInUp">
              <h3 className="rt-title-heading">
                Interested? <span className="letters">Contact us today</span> to
                get started!
              </h3>
            </div>
          </div>
        </div>
        <DashboardRegisterForm />
      </section>
      <Footer history={history} />
    </div>
  );
};

Dashboard.propTypes = {
  history: PropTypes.any,
};

export default Dashboard;
