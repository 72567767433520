import React from 'react';
import PropTypes from 'prop-types';
import { Footer, Header } from 'components';
import './styles.scss';

const Term = ({ history }) => {
  return (
    <>
      <Header history={history} />
      <div className="omny-registerTerm">
        <section id="policy">
          <div className="container">
            <div className="policy">
              <div>
                <h3>LUNEX TELECOM, INC. WEBSITE&apos;S TERMS OF USE</h3>
                <p>
                  Welcome to the website (the &quot;Site&quot;) of Lunex
                  Telecom, Inc., (&quot;Lunex&quot;). Lunex provides its
                  services to you subject to the notices, terms, and conditions
                  set forth in this agreement (the &quot;Agreement&quot;). In
                  addition, when you use any of Lunex services (e.g., purchase
                  of phone cards), you will be subject to the rules, guidelines,
                  policies, terms, and conditions applicable to such service,
                  and they are incorporated into this Agreement by this
                  reference. Lunex reserves the right to change this Site and
                  these terms and conditions at any time.
                  <strong>
                    {' '}
                    ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES
                    YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS IN THIS
                    AGREEMENT, SO PLEASE READ THIS AGREEMENT CAREFULLY BEFORE
                    PROCEEDING.
                  </strong>
                </p>
                <h2>USE OF SITE</h2>
                <p>
                  Subject to the terms and conditions of this Agreement, Lunex
                  hereby grants you a limited, revocable, non-transferable and
                  non-exclusive license to access and use the Site by displaying
                  it on your internet browser only for the purpose of shopping
                  for personal items sold on the Site and not for any commercial
                  use or use on behalf of any third party, except as explicitly
                  permitted by Lunex in advance. Any breach of this Agreement
                  shall result in the immediate revocation of the license
                  granted in this paragraph without notice to you.
                </p>
                <p>
                  Except as permitted in the paragraph above, you may not
                  reproduce, distribute, display, sell, lease, transmit, create
                  derivative works from, translate, modify, reverse-engineer,
                  disassemble, decompile or otherwise exploit this Site or any
                  portion of it unless expressly permitted by Lunex in writing.
                  You may not make any commercial use of any of the information
                  provided on the Site or make any use of the Site for the
                  benefit of another business unless explicitly permitted by
                  Lunex in advance. Lunex reserves the right to refuse service,
                  terminate accounts, and/or cancel orders at its discretion,
                  including, without limitation, if Companies believes that
                  customer conduct violates applicable law or is harmful to
                  Lunex&apos;s interests.
                </p>
                <p>
                  You shall not upload to, distribute, or otherwise publish
                  through this Site any Content, information, or other material
                  that (a) violates or infringes the copyrights, patents,
                  trademarks, service marks, trade secrets, or other proprietary
                  rights of any person; (b) is libelous, threatening,
                  defamatory, obscene, indecent, pornographic, or could give
                  rise to any civil or criminal liability under U.S. or
                  international law; or (c) includes any bugs, viruses, worms,
                  trap doors, Trojan horses or other harmful code or properties.
                </p>
                <p>
                  You represent and warrant that you are at least 18 years old
                  or visiting the Site under the supervision of a parent or
                  guardian.
                </p>
                <p>
                  Content provided on this site is solely for informational
                  purposes. Lunex may assign you a password and account
                  identification to enable you to access and use certain
                  portions of this Site. Each time you use a password or
                  identification, you will be deemed to be authorized to access
                  and use the Site in a manner consistent with the terms and
                  conditions of this Agreement, and Lunex have no obligation to
                  investigate the authorization or source of any such access or
                  use of the Site.
                  <strong>
                    {' '}
                    YOU WILL BE SOLELY RESPONSIBLE FOR ALL ACCESS TO AND USE OF
                    THIS SITE BY ANYONE USING THE PASSWORD AND IDENTIFICATION
                    ORIGINALLY ASSIGNED TO YOU WHETHER OR NOT SUCH ACCESS TO AND
                    USE OF THIS SITE IS ACTUALLY AUTHORIZED BY YOU, INCLUDING
                    WITHOUT LIMITATION, ALL COMMUNICATIONS AND TRANSMISSIONS AND
                    ALL OBLIGATIONS (INCLUDING WITHOUT LIMITATION FINANCIAL
                    OBLIGATIONS) INCURRED THROUGH SUCH ACCESS OR USE.
                  </strong>{' '}
                  You are solely responsible for protecting the security and
                  confidentiality of the password and identification assigned to
                  you. You shall immediately notify Lunex of any unauthorized
                  use of your password or identification or any other breach or
                  threatened breach of this Site&apos;s security.
                </p>
                <h2>INTELLECTUAL PROPERTY</h2>
                <p>
                  All text, graphics, button, icons, images and software
                  (collectively, &quot;Content&quot;), belongs exclusively to
                  Lunex. The collection, arrangement, and assembly of all
                  Content on this Site (the &quot;Compilation&quot;) belongs
                  exclusively to Lunex. All software used on this Site (the
                  &quot;Software&quot;) is the property of Lunex or its Software
                  suppliers. The Content, the Compilation and the Software are
                  all protected by U.S. and international copyright laws. Lunex
                  Telecom and other logos, slogans, trade names or words are
                  registered trademarks, trademarks or service marks of Lunex.
                  The use of any Lunex trademark or service mark without
                  Lunex&apos;s express written consent is strictly prohibited.
                  You may not use any of Lunex trade or service mark in
                  connection with any non Lunex product or service or in any way
                  that is likely to cause confusion. You may not use
                  Lunex&apos;s trademarks or service marks in any manner that
                  disparages or discredits Lunex.
                </p>
                <h2>PRIVACY POLICY</h2>
                <p>
                  Please review our Privacy Policy, which also governs your
                  visit to this Site, to understand our practices. Our Privacy
                  Policy is available at lunextelecom.com.
                </p>
                <h2>RISK OF LOSS; OTHER TERMS OF SALE</h2>
                <p>
                  The risk of loss and title for items purchased by you pass to
                  you upon Lunex&apos;s delivery of the items to the carrier
                  pursuant to Lunex&apos;s standard terms of sale.
                </p>
                <h2>TERMINATION AND EFFECT OF TERMINATION</h2>
                <p>
                  In addition to any other legal or equitable remedies, Lunex
                  may, without prior notice to you, immediately terminate the
                  Agreement or revoke any or all of your rights granted under
                  this Agreement. Upon any termination of this Agreement, you
                  shall immediately cease all access to and use of the Site and
                  Companies shall, in addition to any other legal or equitable
                  remedies, immediately revoke all password(s) and account
                  identification issued to you and deny your access to and use
                  of this Site in whole or in part. Any termination of this
                  Agreement shall not affect the respective rights and
                  obligations (including without limitation, payment
                  obligations) of the parties arising before the date of
                  termination.
                </p>
                <h2>INTERNATIONAL ACCESS</h2>
                <p>
                  This Site may be accessed from countries other than the United
                  States. This Site may contain products or references to
                  products that are not available outside of the United States.
                  Any such references do not imply that such products will be
                  made available outside the United States. If you access and
                  use this Site outside the United States you are responsible
                  for complying with your local laws and regulations.
                </p>
                <h2>DISCLAIMER AND LIMITATION OF LIABILITY</h2>
                <p>
                  EXCEPT AS OTHERWISE PROVIDED IN THE STANDARD TERMS OF SALE
                  THAT GOVERN THE SALE OF EACH PRODUCT ON THIS SITE, A COPY OF
                  WHICH YOU MAY ACCESS HERE AT lunextelecom.com (and on the
                  webpages to which that webpage links), THIS SITE, THE PRODUCTS
                  OFFERED FOR SALE ON IT AND THE TRANSACTIONS CONDUCTED THROUGH
                  IT ARE PROVIDED BY LUNEX ON AN &quot;AS IS&quot; BASIS. LUNEX
                  MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
                  IMPLIED, AS TO THE OPERATION OF THE SITE OR THE INFORMATION,
                  CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE EXCEPT
                  AS PROVIDED HERE AT lunextelecom.com (and on the webpages to
                  which that webpage links). TO THE FULL EXTENT PERMISSIBLE BY
                  APPLICABLE LAW, LUNEX DISCLAIMS ALL WARRANTIES, EXPRESS OR
                  IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                  MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE,
                  NONINFRINGEMENT, TITLE, QUIET ENJOYMENT, DATA ACCURACY AND
                  SYSTEM INTEGRATION. THIS SITE MAY INCLUDE INACCURACIES,
                  MISTAKES OR TYPOGRAPHICAL ERRORS. LUNEX DOES NOT WARRANT THAT
                  THE CONTENT WILL BE UNINTERRUPTED OR ERROR FREE. TO THE
                  MAXIMUM EXTENT PERMITTED BY LAW, LUNEX WILL NOT BE LIABLE FOR
                  ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE,
                  INCLUDING, BUT NOT LIMITED TO INDIRECT, INCIDENTAL, PUNITIVE,
                  EXEMPLARY, SPECIAL OR CONSEQUENTIAL DAMAGES. TO THE MAXIMUM
                  EXTENT PERMITTED BY APPLICABLE LAW, LUNEX&apos;S TOTAL
                  LIABILITY TO YOU FOR ANY DAMAGES (REGARDLESS OF THE FOUNDATION
                  FOR THE ACTION) SHALL NOT EXCEED IN THE AGGREGATE THE AMOUNT
                  OF FEES ACTUALLY PAID BY YOU TO LUNEX DURING THE MONTH
                  IMMEDIATELY PRECEDING THE ACTALLEGEDLY GIVING RISE TO
                  LUNEX&apos;S LIABILITY.
                </p>
                <h2>TYPOGRAPHICAL ERRORS</h2>
                <p>
                  In the event a product is listed at an incorrect price or with
                  incorrect information due to typographical error or error in
                  pricing or product information received from our suppliers,
                  Lunex shall have the right to refuse or cancel any orders
                  placed for product listed at the incorrect price. Lunex shall
                  have the right to refuse or cancel any such orders whether or
                  not the order has been confirmed and your credit card charged.
                  If your credit card has already been charged for the purchase
                  and your order is canceled, Lunex shall immediately issue a
                  credit to your credit card account in the amount of the
                  charge.
                </p>
                <h2>LINKS</h2>
                <p>
                  This site may contain links to other sites on the Internet
                  that are owned and operated by third parties. You acknowledge
                  that Lunex is not responsible for the operation of or content
                  located on or through any such site.
                </p>
                <h2>REMEDIES</h2>
                <p>
                  You agree that Lunex&apos;s remedy at law for any actual or
                  threatened breach of this Agreement would be inadequate and
                  that Lunex shall be entitled to specific performance or
                  injunctive relief, or both, in addition to any damages that
                  Lunex may be legally entitled to recover, together with
                  reasonable expenses of any form of dispute resolution,
                  including, without limitation, attorneys&apos; fees.
                </p>
                <p>
                  No right or remedy of Lunex shall be exclusive of any other,
                  whether at law or in equity, including without limitation
                  damages injunctive relief, attorneys&apos; fees and expenses.
                  No instance of waiver by Lunex of its rights or remedies under
                  these terms and conditions shall imply any obligation to grant
                  any similar, future or other waiver.
                </p>
                <h2>APPLICABLE LAW</h2>
                <p>
                  This site is created and controlled by Lunex Telecom, Inc. in
                  the State of Georgia, USA. As such, the laws of the State of
                  Georgia will govern these disclaimers, terms, and conditions,
                  without giving effect to any principles of conflicts of laws.
                </p>
                <h2>DISPUTES</h2>
                <p>
                  Any dispute relating in any way to your visit to the Site or
                  to the products you purchase through the Site shall be
                  submitted to confidential arbitration in Gwinnett County,
                  Georgia, except that to the extent you have in any manner
                  violated or threatened to violate Lunex&apos;s intellectual
                  property rights, Lunex may seek injunctive or other
                  appropriate relief in any state or federal court in the State
                  of Georgia. You hereby consent to, and waive all defenses of
                  lack of personal jurisdiction and forum non convenience with
                  respect to venue and jurisdiction in the state and federal
                  courts of Georgia. Arbitration under these Terms of Use shall
                  be conducted pursuant to the Commercial Arbitration Rules then
                  prevailing at the American Arbitration Association. The
                  arbitrator&apos;s award shall be final and binding and may be
                  entered as a judgment in any court of competent jurisdiction.
                  To the fullest extent permitted by applicable law, no
                  arbitration under this Agreement shall be joined to an
                  arbitration involving any other party subject to this
                  Agreement, whether through class action proceedings or
                  otherwise. You agree that regardless of any statute or law to
                  the contrary, any claim or cause of action arising out of,
                  related to or connected with the use of the Site or this
                  Agreement must be filed within one (1) year after such claim
                  or cause of action arose or be forever banned.
                </p>
                <h2>SEVERABILITY</h2>
                <p>
                  If any these provisions shall be deemed invalid, void, or for
                  any reason unenforceable, that condition shall be deemed
                  several and shall not affect the validity and enforceability
                  of any remaining provision.
                </p>
                <h2>PRODUCTS</h2>
                <p>
                  <strong>International Calling Terms & Conditions</strong>
                </p>
                <p>
                  Rates may vary depending on destination number within the
                  country. Advertised rates and/or minutes do not include
                  regulatory and carrier recovery cost. Regulatory and carrier
                  recovery cost of ninety nine cents per call may apply. Rates
                  and fees are subject to change at any time without prior
                  notice. If there are no account activities within 12 months,
                  then the account will be deactivated. Calls to destinations
                  where telecommunication networks are not technologically
                  advanced may result in calls getting cut off. Lunex is not
                  responsible for loss, theft, unauthorized use of International
                  Calling account, customer&apos;s misdialing, misusage of local
                  access numbers, and any surcharges from customer’s
                  carrier/provider. International Calling account may be
                  deactivated if fraudulent activities detected. International
                  Calling feature will not work if Caller ID is blocked or
                  called from a pay phone. International Calling has no cash
                  value, not transferable, not refundable. Purchase can only be
                  cancelled within 24 hours after purchased if unused. For
                  additional information regarding rates, terms and conditions
                  governing the use of this, please contact Customer Support at{' '}
                  <strong>1-888-755-3535</strong> or visit{' '}
                  <a href="www.lunextelecom.com" target="_blank">
                    www.lunextelecom.com
                  </a>
                  .
                </p>
                <p>
                  <strong>Charges and Payment</strong>
                </p>
                <p>
                  <strong> Price, Feature and Rate Changes</strong>
                </p>
                <p>
                  Lunex may change the prices or features associated with the
                  Service from time to time, and such changes will be effective
                  from the time they are posted or available through the
                  Website. The terms and conditions of the Service, including
                  the rates for calls made and/or available destinations in
                  conjunction with the use of the Service are subject to change
                  at any time without prior notice. All calls for the
                  International Calling Service are billed in one minute
                  increments. The International Calling Service expires 365 days
                  after last use or 30 days after account suspension. There are
                  no additional fees or surcharges associated with the Services.
                  You may contact Customer Service at 1-888-755-3535 or visit
                  the{' '}
                  <a href="www.lunextelecon.com" target="_blank">
                    www.lunextelecon.com
                  </a>{' '}
                  if You have any questions about the Service, including the
                  international long distance rates or available destinations
                  associated with the Service.{' '}
                </p>
                <p>
                  <strong>Suspending/Canceling the Service </strong>
                </p>
                <p>
                  If Lunex has reason to believe that You or someone else is
                  abusing the Service fraudulently or unlawfully through Your
                  Account, or if Your form of payment for the Service is
                  cancelled, disabled, discontinued or otherwise dishonored,
                  Lunex reserves the right to immediately suspend, restrict,
                  deactivate or cancel the Service and close Your Account
                  without advance notice. In the event of suspension or
                  cancellation of the Service due to any other aforementioned
                  reasons, Lunex may from time to time discontinue offering the
                  Service, or if necessary, cancel all Accounts, immediately
                  upon a determination by any governmental authority that the
                  provision of the Service is contrary to any law, rule or
                  regulation or upon Your violation of this Consumer Agreement.
                </p>
                <p>
                  The Service may be used for personal telecommunications voice
                  services and may not be used for any other purposes,
                  including, but not limited to, loaning or renting Your handset
                  to third parties, conference calling, monitoring services,
                  data transmissions, transmission of broadcasts, transmission
                  of recorded material, interconnection to other networks,
                  telemarketing, autodialed calls, other commercial uses, or
                  other connections that do not consist of uninterrupted live
                  dialogue between two individuals. If usage, dialing or calling
                  patterns Lunex deems connote non-personal use, Lunex reserves
                  the right to suspend, terminate or restrict Your Services with
                  no prior notice and not allow You to reactivate the Service.
                </p>
                <p>
                  Lunex is not responsible for any ancillary costs incurred by
                  You, including but not limited to, cell phone provider charges
                  or fees and/or penalties incurred by the You in the event You
                  use the Service for any unlawful purpose.
                </p>
                <p>
                  <strong>Restrictions on Use; Intellectual Property</strong>
                </p>
                <p>
                  All of Lunex trademarks, service marks, symbols, logos, other
                  identifying indicia and the intellectual property rights
                  thereto (collectively “Marks”) are the property of Lunex and
                  You shall have no right in or to the Marks or any right to use
                  the Marks including any and all Marks associated with the
                  Service and the Website. You are not permitted to commercially
                  resell the Service or to print, produce, sell or distribute
                  anything containing the Marks or to sell or distribute any
                  telecommunication products that use or are associated with the
                  Service.
                </p>
                <p>
                  <strong>Indemnification</strong>
                </p>
                <p>
                  You agree that Lunex shall not be responsible for any third
                  party claims against Lunex and/or You that arise from Your use
                  of the Service or Your Account. Further, You agree to
                  reimburse Lunex for all costs and expenses related to the
                  defense of any such claims, including attorneys’ fees, unless
                  such claims are based upon Our willful misconduct or gross
                  negligence. This provision will continue to remain in force
                  after the Agreement expires or is otherwise terminated
                </p>
                <p>
                  <strong>Limitations of Liability</strong>
                </p>
                <p>
                  This section describes Lunex’s responsibility for any claims
                  for damages You may assert arising out of the failure of the
                  Service or any other claims You may assert in connection with
                  the Service, or in connection with the transfer of funds to
                  Your Account or with this Agreement. Lunex is not liable for
                  any Service failures. Except as otherwise expressly provided
                  herein, Lunex and Our affiliates, authorized distributors,
                  partners, directors, officers, members and employees are not
                  liable to You or to any third party for any costs, liabilities
                  or damages arising either directly or indirectly from any
                  cause, action or claim relating to the Service, the Website
                  and/or the telecommunication services underlying the Service,
                  including without limitation any actual, incidental,
                  consequential, exemplary, punitive, reliance or special
                  damages, or for any claims for loss of revenue, lost profits,
                  lost use, data, or goodwill or for lost business opportunities
                  of any kind or nature whatsoever. These limitations apply even
                  if the damages were foreseeable or Lunex was told that they
                  were possible, and these limitations apply whether the claim
                  is based upon contract, tort, statute, fraud,
                  misrepresentation, or any other legal or equitable theory.
                  Lunex will not be liable for any damages if the
                  telecommunication services underlying the Service are
                  interrupted, or if there is a problem with the interconnection
                  of the Service with the service, products or equipment of some
                  other party. This section will survive the expiration or
                  termination of the Agreement. Because some jurisdictions do
                  not permit the exclusions or limitation of incidental or
                  consequential damages, Lunex’s liability in such jurisdictions
                  shall be limited to the extent permitted by law.
                </p>
                <p>
                  <strong>Warranties</strong>
                </p>
                <p>
                  You acknowledge that (a) the Service is provided by Lunex “AS
                  IS”, and (b) Lunex makes no warranty to You or to any third
                  party whatsoever, directly or indirectly, express, implied or
                  statutory, as to the suitability, durability, description,
                  quality, title, non-infringement, merchantability,
                  completeness or fitness for use or purpose of the Service or
                  any Lunex product associated with the Service. All such
                  warranties are hereby expressly excluded and disclaimed. Lunex
                  also makes no warranty that the Service will be uninterrupted
                  or error free. Lunex does not authorize anyone, including, but
                  not limited to, Lunex employees, to make any warranties on Our
                  behalf and You should not rely on any such statement. Your use
                  of the Service is solely at Your risk. Because some
                  jurisdictions do no permit the exclusion of certain
                  warranties, these exclusions may not apply to You.
                </p>
                <p>
                  <strong>Miscellaneous</strong>
                </p>
                <p>
                  <strong>Emergency Calls</strong>
                </p>
                <p>
                  Lunex’s applications and services are not a replacement for
                  Your cellular service and You will not be able to make
                  emergency calls (e.g. 911) using them. Our services do not
                  prevent You from using Your phone to make emergency calls,
                  however cellular service may not be available everywhere. If
                  You are in a location without cellular service You will need
                  to make other arrangements. Emergency calls are NOT supported
                  outside the domestic USA.
                </p>
                <p>
                  <strong>No Third Party Rights</strong>
                </p>
                <p>
                  This Agreement does not provide any third party with a remedy,
                  claim, or right of reimbursement.
                </p>
                <p>
                  <strong>Acts Beyond Our Control</strong>
                </p>
                <p>
                  Neither You or Lunex will be responsible or liable to the
                  other for any failure to perform duties under this Consumer
                  Agreement where that failure is due to an act outside the
                  reasonable control of You, or Lunex (Force Majeure Event),
                  including but not limited to the failure to perform due to any
                  of the following: fire, explosion, power blackout, earthquake,
                  volcanic action, flood, the weather elements, strike, embargo,
                  civil or military authority, war, acts of God, acts or
                  omissions of carriers or suppliers, or acts of regulatory or
                  governmental agencies.
                </p>
                <p>
                  <strong>Assignment</strong>
                </p>
                <p>
                  Lunex can assign all or part of Our rights or duties under
                  this Agreement without notifying You. If Lunex elects to make
                  such an assignment, Lunex will have no further obligations to
                  You under this Agreement or in connection with Your purchase
                  or use of the Products. You may not assign this Agreement
                  without Our prior written consent.
                </p>
                <p>
                  <strong>Notices</strong>
                </p>
                <p>
                  Any notice from Lunex to You under this Agreement will be
                  provided by one or more of the following: on Our Website, a
                  recorded announcement on a Product, letter, or a call to a
                  telephone number provided by You. You may contact Lunex by
                  email at: accounting@lunextelecom.com or by mail at Lunex
                  Telecom - 4500 Satellite Blvd, Suite 2320, Duluth, GA 30096
                </p>
                <p>
                  <strong>Separability</strong>
                </p>
                <p>
                  If any part of this Agreement is found invalid, the rest of
                  the Agreement will remain valid and enforceable.
                </p>
                <p>
                  <strong>Governing Law</strong>
                </p>
                <p>
                  This Agreement will be governed by the laws of the State of
                  Georgia, without regard to its choice of law rules. This
                  governing law provision applies no matter where You reside, or
                  where You use, purchase or pay for the Products.
                </p>
                <p>
                  <strong>Entire Agreement</strong>
                </p>
                <p>
                  This Agreement (which incorporates by reference the Website
                  Terms of Use) constitutes the entire agreement between You and
                  Lunex and supersedes all prior agreements, understandings,
                  statements or proposals, and representations, whether written
                  or oral. This Agreement can be amended only as provided in the
                  Changes to the Agreement section below. No written or oral
                  statement, advertisement, or service or product description
                  not expressly contained in the Agreement or the Website will
                  be allowed to contradict, explain, or supplement this
                  Agreement or the Service. Neither You or Lunex is relying on
                  any representations or statements by the other party or any
                  other person that is not included in this Agreement.{' '}
                </p>
                <p>
                  <strong>Changes to the Terms & Conditions</strong>
                </p>
                <p>
                  This Agreement may only be changed in the manner provided for
                  in this section. Lunex may change this Agreement and the
                  information contained elsewhere on the Website from time to
                  time. All such changes will be effective at the time of
                  posting on the Website.
                </p>
                <h2>
                  Top Up Terms & Conditions (Recharge Minutes Cell Phone):
                </h2>
                <p>
                  Lunex is an independent entity appointed by mobile network
                  operators to sell Recharge Minutes services on their behalf.
                  All Recharge Minutes transactions are final and
                  non-refundable. Recharge Minutes service is usually available
                  to use in approximately ten minutes after successful Recharge
                  Minutes; however, delays may occur. Lunex will not be
                  responsible if customer entered wrong recipient&apos;s mobile
                  number. Upon receipt of proper and complete recipient
                  information, Lunex will forward such order to the relevant
                  mobile network operator and will debit the Customer&apos;s
                  account accordingly. Taxes may apply to certain Mobile
                  Providers. Cancellation of purchase: (1) for Top-Up USA (DTU),
                  PIN products, purchase can only be cancelled within 24 hours
                  after purchased if unused; (2) for Top-Up USA (DTU), Real Time
                  Replenishment (RTR) and International Top-Up (ITU) products,
                  purchase cannot be cancelled after sales transaction is
                  completed. All terms and rates are subject to change without
                  notice. For latest terms & conditions, please contact Customer
                  Service.
                </p>
                <h2>OUR ADDRESS</h2>
                <p>
                  Please send any questions or comments (including all inquiries
                  unrelated to copyright infringement) regarding this Site to:
                </p>
                <p>
                  <strong>Lunex Telecom, Inc.</strong>
                  <br />
                  4500 Satellite Blvd, Suite 2320
                  <br />
                  Duluth, GA 30096
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer history={history} />
    </>
  );
};

Term.propTypes = {
  history: PropTypes.any,
};

export default Term;
